import React from "react";

const App = () => {
  return (
    <main>
      <h1>Hosting a React app on Firebase with Github Actions</h1>
      <p>
        This app only exists as a demo to showcase the steps necessary to create a simple CI/CD pipeline utlising Github
        Actions to deploy a React app to Firebase hosting.
      </p>
      <p>
        The steps to recreate this pipeline are detailed in the README.md of{" "}
        <a href="https://github.com/nology-tech/actions-react-hoster" target="blank">
          this repo.
        </a>
      </p>
    </main>
  );
};

export default App;
